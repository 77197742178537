
export const introduce = {
  CN:"安之健康技术（广东）有限公司经营范围： 中医健康监测类软件开发、硬件设计、信息系统集成、工程设计、技术咨询服务、售后维护支持等、进出口业务；保健食品生产及销售；"+
     "医疗器械的生产 , 批发 , 零售（凭许可证经营）；健康管理（须经审批的诊疗活动除外）、健康咨询（须经审批的诊疗活动除外） 。",
  EN:"Anzy Health Technology (Guangdong) Co., Ltd.: Which is a company which focus on human health technology.  Copmany business scope:  software development, "+
     "hardware design, information system integration,engineering design, technical consulting service, after-sales maintenance support,"+
     "import and export business of traditional Chinese medicine health monitoring; production and sales of health food; production and wholesale of medical devices,"+
     "Retail (operate with license); health management (except for approved diagnosis and treatment activities), health consultation (except for approved diagnosis and treatment activities)."
}

export const Address = {
  address1:{
    CN:"深圳市宝安区银田创业园36栋商业中心609室",
    EN:"room 609, building 36, Yintian business center,, Bao'an District, Shenzhen"
  },
  address2:{
    CN:"广东省惠州市仲恺高新区惠风七路7号805室",
    EN:"Room 805, No.7, Road 7th Huifeng, Zhongkai high tech Zone, Huizhou City, Guangdong Province"
  },
  email:"admin@anzyhealth.com",
  phone:"+086 138 2523 0086"

}

export const Productarr = [
  {
    id:2,
    name:{
      CN:"运动健康系列",
      EN:"Sports Health Series"
    },
    least:false,
    text:{
      CN:"",
      EN:""
    },
    products:[],
    children:[
      {
        id:2_1,
        name:{      
          CN:"健康监测智能手表V6",
          EN:"Health Monitoring Smart Watch V6"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[ 
          {  CN:{text:"",src:"product/image_V6 (1).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (2).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (3).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (4).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (5).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (6).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (7).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (8).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (9).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (10).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (11).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (12).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (13).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (14).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (15).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (16).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (17).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (18).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (19).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (20).png"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_V6 (21).png"}, EN:{text:"",src:""}  },
        ],
      },
      {
        id:2_2,
        name:{      
          CN:"健康监测智能手环V5",
          EN:"Health Monitoring Smart Watch V5"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_V5 (1).jpg"}, EN:{text:"",src:"product/image_V5_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (2).jpg"}, EN:{text:"",src:"product/image_V5_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (3).jpg"}, EN:{text:"",src:"product/image_V5_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (4).jpg"}, EN:{text:"",src:"product/image_V5_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (5).jpg"}, EN:{text:"",src:"product/image_V5_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (6).jpg"}, EN:{text:"",src:"product/image_V5_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (7).jpg"}, EN:{text:"",src:"product/image_V5_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (8).jpg"}, EN:{text:"",src:"product/image_V5_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (9).jpg"}, EN:{text:"",src:"product/image_V5_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (10).jpg"}, EN:{text:"",src:"product/image_V5_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (11).jpg"}, EN:{text:"",src:"product/image_V5_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (12).jpg"}, EN:{text:"",src:"product/image_V5_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (13).jpg"}, EN:{text:"",src:"product/image_V5_e (13).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (14).jpg"}, EN:{text:"",src:"product/image_V5_e (14).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (15).jpg"}, EN:{text:"",src:"product/image_V5_e (15).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (16).jpg"}, EN:{text:"",src:"product/image_V5_e (16).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (17).jpg"}, EN:{text:"",src:"product/image_V5_e (17).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (18).jpg"}, EN:{text:"",src:"product/image_V5_e (18).jpg"}  },
          {  CN:{text:"",src:"product/image_V5 (19).jpg"}, EN:{text:"",src:"product/image_V5_e (19).jpg"}  },
        ],
      },
      {
        id:2_3,
        name:{      
          CN:"健康心电手环C18",
          EN:"Healthy ECG bracelet C18"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_C18 (1).jpg"}, EN:{text:"",src:"product/image_C18_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_C18 (2).jpg"}, EN:{text:"",src:"product/image_C18_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_C18 (3).jpg"}, EN:{text:"",src:"product/image_C18_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_C18 (4).jpg"}, EN:{text:"",src:"product/image_C18_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_C18 (5).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (6).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (7).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (8).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (9).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (0).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (11).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (12).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (13).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (14).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_C18 (15).jpg"}, EN:{text:"",src:""}  },
        ],
      },
      {
        id:2_4,
        name:{      
          CN:"T20",
          EN:"T20"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_T20 (1).jpg"}, EN:{text:"",src:"product/image_T20_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (2).jpg"}, EN:{text:"",src:"product/image_T20_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (3).jpg"}, EN:{text:"",src:"product/image_T20_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (4).jpg"}, EN:{text:"",src:"product/image_T20_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (5).jpg"}, EN:{text:"",src:"product/image_T20_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (6).jpg"}, EN:{text:"",src:"product/image_T20_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (7).jpg"}, EN:{text:"",src:"product/image_T20_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (8).jpg"}, EN:{text:"",src:"product/image_T20_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (9).jpg"}, EN:{text:"",src:"product/image_T20_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (10).jpg"}, EN:{text:"",src:"product/image_T20_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (11).jpg"}, EN:{text:"",src:"product/image_T20_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (12).jpg"}, EN:{text:"",src:"product/image_T20_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (13).jpg"}, EN:{text:"",src:"product/image_T20_e (13).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (14).jpg"}, EN:{text:"",src:"product/image_T20_e (14).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (15).jpg"}, EN:{text:"",src:"product/image_T20_e (15).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (16).jpg"}, EN:{text:"",src:"product/image_T20_e (16).jpg"}  },
          {  CN:{text:"",src:"product/image_T20 (17).jpg"}, EN:{text:"",src:"product/image_T20_e (17).jpg"}  },
        ],
      },
      {
        id:2_5,
        name:{      
          CN:"心率血氧监测手表T26",
          EN:"Heart rate and blood oxygen monitoring watch T26"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_T26 (1).jpg"}, EN:{text:"",src:"product/image_T26 (1).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (2).jpg"}, EN:{text:"",src:"product/image_T26 (2).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (3).jpg"}, EN:{text:"",src:"product/image_T26 (3).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (4).jpg"}, EN:{text:"",src:"product/image_T26 (4).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (5).jpg"}, EN:{text:"",src:"product/image_T26 (5).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (6).jpg"}, EN:{text:"",src:"product/image_T26 (6).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (7).jpg"}, EN:{text:"",src:"product/image_T26 (7).jpg "}  },
          {  CN:{text:"",src:"product/image_T26 (8).jpg"}, EN:{text:"",src:"product/image_T26 (8).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (9).jpg"}, EN:{text:"",src:"product/image_T26 (9).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (10).jpg"}, EN:{text:"",src:"product/image_T26 (10).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (11).jpg"}, EN:{text:"",src:"product/image_T26 (11).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (12).jpg"}, EN:{text:"",src:"product/image_T26 (12).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (13).jpg"}, EN:{text:"",src:"product/image_T26 (13).jpg"}  },
          {  CN:{text:"",src:"product/image_T26 (14).jpg"}, EN:{text:"",src:"product/image_T26 (14).jpg"}  },
        ],
      },
      {
        id:2_6,
        name:{      
          CN:"体温监测手环B6W",
          EN:"Body temperature monitoring bracelet B6W"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_B6W.jpg"}, EN:{text:"",src:"product/image_B6W.jpg"}  }
        ],
      },
      {
        id:2_7,
        name:{      
          CN:"无创血糖监测手表EP03",
          EN:"Non invasive blood glucose monitoring watch EP03"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_EP03 (1).jpg"}, EN:{text:"",src:"product/image_EP03 (1).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (2).jpg"}, EN:{text:"",src:"product/image_EP03 (2).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (3).jpg"}, EN:{text:"",src:"product/image_EP03 (3).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (4).jpg"}, EN:{text:"",src:"product/image_EP03 (4).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (5).jpg"}, EN:{text:"",src:"product/image_EP03 (5).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (6).jpg"}, EN:{text:"",src:"product/image_EP03 (6).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (7).jpg"}, EN:{text:"",src:"product/image_EP03 (7).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (8).jpg"}, EN:{text:"",src:"product/image_EP03 (8).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (9).jpg"}, EN:{text:"",src:"product/image_EP03 (9).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (10).jpg"}, EN:{text:"",src:"product/image_EP03 (10).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (11).jpg"}, EN:{text:"",src:"product/image_EP03 (11).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (12).jpg"}, EN:{text:"",src:"product/image_EP03 (12).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (13).jpg"}, EN:{text:"",src:"product/image_EP03 (13).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (14).jpg"}, EN:{text:"",src:"product/image_EP03 (14).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (15).jpg"}, EN:{text:"",src:"product/image_EP03 (15).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (16).jpg"}, EN:{text:"",src:"product/image_EP03 (16).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (17).jpg"}, EN:{text:"",src:"product/image_EP03 (17).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (18).jpg"}, EN:{text:"",src:"product/image_EP03 (18).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (19).jpg"}, EN:{text:"",src:"product/image_EP03 (19).jpg"}  },
          {  CN:{text:"",src:"product/image_EP03 (20).jpg"}, EN:{text:"",src:"product/image_EP03 (20).jpg"}  },
        ],
      },
      {
        id:2_8,
        name:{      
          CN:"H16",
          EN:"H16"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_H16_e (1).jpg"}, EN:{text:"",src:"product/image_H16_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (2).jpg"}, EN:{text:"",src:"product/image_H16_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (3).jpg"}, EN:{text:"",src:"product/image_H16_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (4).jpg"}, EN:{text:"",src:"product/image_H16_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (5).jpg"}, EN:{text:"",src:"product/image_H16_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (6).jpg"}, EN:{text:"",src:"product/image_H16_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (7).jpg"}, EN:{text:"",src:"product/image_H16_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (8).jpg"}, EN:{text:"",src:"product/image_H16_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (9).jpg"}, EN:{text:"",src:"product/image_H16_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (10).jpg"}, EN:{text:"",src:"product/image_H16_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (11).jpg"}, EN:{text:"",src:"product/image_H16_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (12).jpg"}, EN:{text:"",src:"product/image_H16_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (13).jpg"}, EN:{text:"",src:"product/image_H16_e (13).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (14).jpg"}, EN:{text:"",src:"product/image_H16_e (14).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (15).jpg"}, EN:{text:"",src:"product/image_H16_e (15).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (16).jpg"}, EN:{text:"",src:"product/image_H16_e (16).jpg"}  },
          {  CN:{text:"",src:"product/image_H16_e (17).jpg"}, EN:{text:"",src:"product/image_H16_e (17).jpg"}  },
        ],
      },
      {
        id:2_9,
        name:{      
          CN:"智能健康监测手环C9",
          EN:"Intelligent Health Monitoring Bracelet C9"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_C9 (1).jpg"}, EN:{text:"",src:"product/image_C9_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (2).jpg"}, EN:{text:"",src:"product/image_C9_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (3).jpg"}, EN:{text:"",src:"product/image_C9_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (4).jpg"}, EN:{text:"",src:"product/image_C9_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (5).jpg"}, EN:{text:"",src:"product/image_C9_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (6).jpg"}, EN:{text:"",src:"product/image_C9_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (7).jpg"}, EN:{text:"",src:"product/image_C9_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (8).jpg"}, EN:{text:"",src:"product/image_C9_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (9).jpg"}, EN:{text:"",src:"product/image_C9_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (10).jpg"}, EN:{text:"",src:"product/image_C9_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (11).jpg"}, EN:{text:"",src:"product/image_C9_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (12).jpg"}, EN:{text:"",src:"product/image_C9_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (13).jpg"}, EN:{text:"",src:"product/image_C9_e (13).jpg"}  },
          {  CN:{text:"",src:"product/image_C9 (14).jpg"}, EN:{text:"",src:"product/image_C9_e (14).jpg"}  },
          {  CN:{text:"",src:""}, EN:{text:"",src:"product/image_C9_e (15).jpg"}  },
        ],
      },
      {
        id:2_10,
        name:{      
          CN:"蓝牙通话智能手表T10",
          EN:"Bluetooth call smartwatch T10"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_T10 (1).jpg"}, EN:{text:"",src:"product/image_T10_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (2).jpg"}, EN:{text:"",src:"product/image_T10_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (3).jpg"}, EN:{text:"",src:"product/image_T10_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (4).jpg"}, EN:{text:"",src:"product/image_T10_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (5).jpg"}, EN:{text:"",src:"product/image_T10_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (6).jpg"}, EN:{text:"",src:"product/image_T10_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (7).jpg"}, EN:{text:"",src:"product/image_T10_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (8).jpg"}, EN:{text:"",src:"product/image_T10_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (9).jpg"}, EN:{text:"",src:"product/image_T10_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (10).jpg"}, EN:{text:"",src:"product/image_T10_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (11).jpg"}, EN:{text:"",src:"product/image_T10_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (12).jpg"}, EN:{text:"",src:"product/image_T10_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (13).jpg"}, EN:{text:"",src:"product/image_T10_e (13).jpg"}  },
          {  CN:{text:"",src:"product/image_T10 (14).jpg"}, EN:{text:"",src:"product/image_T10_e (14).jpg"}  },
        ],
      },
      {
        id:2_11,
        name:{      
          CN:"蓝牙耳机手环TB02",
          EN:"Bluetooth earphone bracelet TB02"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_TB02 (1).jpg"}, EN:{text:"",src:"product/image_TB02_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (2).jpg"}, EN:{text:"",src:"product/image_TB02_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (3).jpg"}, EN:{text:"",src:"product/image_TB02_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (4).jpg"}, EN:{text:"",src:"product/image_TB02_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (5).jpg"}, EN:{text:"",src:"product/image_TB02_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (6).jpg"}, EN:{text:"",src:"product/image_TB02_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (7).jpg"}, EN:{text:"",src:"product/image_TB02_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (8).jpg"}, EN:{text:"",src:"product/image_TB02_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (9).jpg"}, EN:{text:"",src:"product/image_TB02_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (10).jpg"}, EN:{text:"",src:"product/image_TB02_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (11).jpg"}, EN:{text:"",src:"product/image_TB02_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (12).jpg"}, EN:{text:"",src:"product/image_TB02_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (13).jpg"}, EN:{text:"",src:"product/image_TB02_e (13).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (14).jpg"}, EN:{text:"",src:"product/image_TB02_e (14).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (15).jpg"}, EN:{text:"",src:"product/image_TB02_e (15).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (16).jpg"}, EN:{text:"",src:"product/image_TB02_e (16).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (17).jpg"}, EN:{text:"",src:"product/image_TB02_e (17).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (18).jpg"}, EN:{text:"",src:"product/image_TB02_e (18).jpg"}  },
          {  CN:{text:"",src:"product/image_TB02 (19).jpg"}, EN:{text:"",src:"product/image_TB02_e (19).jpg"}  },
        ],
      },
      {
        id:2_12,
        name:{      
          CN:"运动时尚手环C7",
          EN:"Sports Fashion Bracelet C7"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_C7 (1).jpg"}, EN:{text:"",src:"product/image_C7_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (2).jpg"}, EN:{text:"",src:"product/image_C7_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (3).jpg"}, EN:{text:"",src:"product/image_C7_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (4).jpg"}, EN:{text:"",src:"product/image_C7_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (5).jpg"}, EN:{text:"",src:"product/image_C7_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (6).jpg"}, EN:{text:"",src:"product/image_C7_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (7).jpg"}, EN:{text:"",src:"product/image_C7_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (8).jpg"}, EN:{text:"",src:"product/image_C7_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (9).jpg"}, EN:{text:"",src:"product/image_C7_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (10).jpg"}, EN:{text:"",src:"product/image_C7_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (11).jpg"}, EN:{text:"",src:"product/image_C7_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (12).jpg"}, EN:{text:"",src:"product/image_C7_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (13).jpg"}, EN:{text:"",src:"product/image_C7_e (13).jpg"}  },
          {  CN:{text:"",src:"product/image_C7 (14).jpg"}, EN:{text:"",src:"product/image_C7_e (14).jpg"}  },
        ],
      },
      {
        id:2_13,
        name:{      
          CN:"健康运动手环S10",
          EN:"Healthy Sports Bracelet S10"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_S10 (1).jpg"}, EN:{text:"",src:"product/image_S10_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (2).jpg"}, EN:{text:"",src:"product/image_S10_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (3).jpg"}, EN:{text:"",src:"product/image_S10_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (4).jpg"}, EN:{text:"",src:"product/image_S10_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (5).jpg"}, EN:{text:"",src:"product/image_S10_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (6).jpg"}, EN:{text:"",src:"product/image_S10_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (7).jpg"}, EN:{text:"",src:"product/image_S10_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (8).jpg"}, EN:{text:"",src:"product/image_S10_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (9).jpg"}, EN:{text:"",src:"product/image_S10_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (10).jpg"}, EN:{text:"",src:"product/image_S10_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (11).jpg"}, EN:{text:"",src:"product/image_S10_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (12).jpg"}, EN:{text:"",src:"product/image_S10_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_S10 (13).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_S10 (14).jpg"}, EN:{text:"",src:""}  },
        ],
      },
      {
        id:2_14,
        name:{      
          CN:"运动健康手环C11",
          EN:"Sports and Health Bracelet C11"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_C11 (1).jpg"}, EN:{text:"",src:"product/image_C11_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (2).jpg"}, EN:{text:"",src:"product/image_C11_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (3).jpg"}, EN:{text:"",src:"product/image_C11_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (4).jpg"}, EN:{text:"",src:"product/image_C11_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (5).jpg"}, EN:{text:"",src:"product/image_C11_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (6).jpg"}, EN:{text:"",src:"product/image_C11_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (7).jpg"}, EN:{text:"",src:"product/image_C11_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (8).jpg"}, EN:{text:"",src:"product/image_C11_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (9).jpg"}, EN:{text:"",src:"product/image_C11_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (10).jpg"}, EN:{text:"",src:"product/image_C11_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (11).jpg"}, EN:{text:"",src:"product/image_C11_e (11).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (12).jpg"}, EN:{text:"",src:"product/image_C11_e (12).jpg"}  },
          {  CN:{text:"",src:"product/image_C11 (13).jpg"}, EN:{text:"",src:"product/image_C11_e (13).jpg"}  },
        ],
      },
    ]
  },
  {
    id:3,
    name:{
      CN:"老人手表系列",
      EN:"Old Man Watch Series"
    },
    least:false,
    text:{
      CN:"",
      EN:""
    },
    products:[],
    children:[
      {
        id:3_1,
        name:{      
          CN:"智能老年手表S1",
          EN:"Smart Elderly Watch S1"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_S1 (1).png"}, EN:{text:"",src:"product/image_S1_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (2).png"}, EN:{text:"",src:"product/image_S1_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (3).png"}, EN:{text:"",src:"product/image_S1_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (4).png"}, EN:{text:"",src:"product/image_S1_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (5).png"}, EN:{text:"",src:"product/image_S1_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (6).png"}, EN:{text:"",src:"product/image_S1_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (7).jpg"}, EN:{text:"",src:"product/image_S1_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (8).jpg"}, EN:{text:"",src:"product/image_S1_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (9).jpg"}, EN:{text:"",src:"product/image_S1_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_S1 (10).jpg"}, EN:{text:"",src:"product/image_S1_e (10).jpg"}  },

        ],
      },
      {
        id:3_2,
        name:{      
          CN:"老年手环S3",
          EN:"Elderly bracelet S3"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_S3 (1).jpg"}, EN:{text:"",src:"product/image_S3_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (2).jpg"}, EN:{text:"",src:"product/image_S3_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (3).jpg"}, EN:{text:"",src:"product/image_S3_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (4).jpg"}, EN:{text:"",src:"product/image_S3_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (5).jpg"}, EN:{text:"",src:"product/image_S3_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (6).jpg"}, EN:{text:"",src:"product/image_S3_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (7).jpg"}, EN:{text:"",src:"product/image_S3_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (8).jpg"}, EN:{text:"",src:"product/image_S3_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (9).jpg"}, EN:{text:"",src:"product/image_S3_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (10).jpg"}, EN:{text:"",src:"product/image_S3_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_S3 (11).jpg"}, EN:{text:"",src:""}  },
        ],
      },
      {
        id:3_3,
        name:{      
          CN:"智能健康老年手环I9",
          EN:"Intelligent Healthy Elderly Bracelet I9"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_I9 (1).jpg"}, EN:{text:"",src:"product/image_I9 (1).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (2).jpg"}, EN:{text:"",src:"product/image_I9 (2).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (3).jpg"}, EN:{text:"",src:"product/image_I9 (3).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (4).jpg"}, EN:{text:"",src:"product/image_I9 (4).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (5).jpg"}, EN:{text:"",src:"product/image_I9 (5).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (6).jpg"}, EN:{text:"",src:"product/image_I9 (6).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (7).jpg"}, EN:{text:"",src:"product/image_I9 (7).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (8).jpg"}, EN:{text:"",src:"product/image_I9 (8).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (9).jpg"}, EN:{text:"",src:"product/image_I9 (9).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (10).jpg"}, EN:{text:"",src:"product/image_I9 (10).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (11).jpg"}, EN:{text:"",src:"product/image_I9 (11).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (12).jpg"}, EN:{text:"",src:"product/image_I9 (12).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (13).jpg"}, EN:{text:"",src:"product/image_I9 (13).jpg"}  },
          {  CN:{text:"",src:"product/image_I9 (14).jpg"}, EN:{text:"",src:"product/image_I9 (14).jpg"}  },
        ],
      },
      {
        id:3_4,
        name:{      
          CN:"智能老人手表 I6E",
          EN:"Intelligent Elderly Watch I6E"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_I6E (1).jpg"}, EN:{text:"",src:"product/image_I6E_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (2).jpg"}, EN:{text:"",src:"product/image_I6E_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (3).jpg"}, EN:{text:"",src:"product/image_I6E_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (4).jpg"}, EN:{text:"",src:"product/image_I6E_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (5).jpg"}, EN:{text:"",src:"product/image_I6E_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (6).jpg"}, EN:{text:"",src:"product/image_I6E_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (7).jpg"}, EN:{text:"",src:"product/image_I6E_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (8).jpg"}, EN:{text:"",src:"product/image_I6E_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (9).jpg"}, EN:{text:"",src:"product/image_I6E_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (10).jpg"}, EN:{text:"",src:"product/image_I6E_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_I6E (11).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_I6E (12).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_I6E (13).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_I6E (14).jpg"}, EN:{text:"",src:""}  },
        ],
      },
      {
        id:3_5,
        name:{      
          CN:"带SIM卡定位老人智能手环K9L",
          EN:"Intelligent elderly bracelet K9L with SIM card positioning"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_K9L_e (1).jpg"}, EN:{text:"",src:"product/image_K9L_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_K9L_e (2).jpg"}, EN:{text:"",src:"product/image_K9L_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_K9L_e (3).jpg"}, EN:{text:"",src:"product/image_K9L_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_K9L_e (4).jpg"}, EN:{text:"",src:"product/image_K9L_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_K9L_e (5).jpg"}, EN:{text:"",src:"product/image_K9L_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_K9L_e (6).jpg"}, EN:{text:"",src:"product/image_K9L_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_K9L_e (7).jpg"}, EN:{text:"",src:"product/image_K9L_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_K9L_e (8).jpg"}, EN:{text:"",src:"product/image_K9L_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_K9L_e (9).jpg"}, EN:{text:"",src:"product/image_K9L_e (9).jpg"}  }
        ],
      },
    ]
  },
  {
    id:4,
    name:{
      CN:"儿童手表系列",
      EN:"Children's watch series"
    },
    least:false,
    text:{
      CN:"",
      EN:""
    },
    products:[],
    children:[
      {
        id:4_1,
        name:{      
          CN:"智能儿童手表A81",
          EN:"Smart Children's Watch A81"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_A81 (1).jpg"}, EN:{text:"",src:"product/image_A81_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (2).jpg"}, EN:{text:"",src:"product/image_A81_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (3).jpg"}, EN:{text:"",src:"product/image_A81_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (4).jpg"}, EN:{text:"",src:"product/image_A81_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (5).jpg"}, EN:{text:"",src:"product/image_A81_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (6).jpg"}, EN:{text:"",src:"product/image_A81_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (7).jpg"}, EN:{text:"",src:"product/image_A81_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (8).jpg"}, EN:{text:"",src:"product/image_A81_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (9).jpg"}, EN:{text:"",src:"product/image_A81_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (10).jpg"}, EN:{text:"",src:"product/image_A81_e (10).jpg"}  },
          {  CN:{text:"",src:"product/image_A81 (11).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_A81 (12).jpg"}, EN:{text:"",src:""}  },
          {  CN:{text:"",src:"product/image_A81 (13).jpg"}, EN:{text:"",src:""}  },
        ],
      },
      {
        id:4_2,
        name:{      
          CN:"智能儿童手环G9",
          EN:"Smart Children's Bracelet G9"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_G9_e (1).png"}, EN:{text:"",src:"product/image_G9_e (1).png"}  },
          {  CN:{text:"",src:"product/image_G9_e (2).png"}, EN:{text:"",src:"product/image_G9_e (2).png"}  },
          {  CN:{text:"",src:"product/image_G9_e (3).png"}, EN:{text:"",src:"product/image_G9_e (3).png"}  },
          {  CN:{text:"",src:"product/image_G9_e (4).png"}, EN:{text:"",src:"product/image_G9_e (4).png"}  },
          {  CN:{text:"",src:"product/image_G9_e (5).png"}, EN:{text:"",src:"product/image_G9_e (5).png"}  },
          {  CN:{text:"",src:"product/image_G9_e (6).png"}, EN:{text:"",src:"product/image_G9_e (6).png"}  },
          {  CN:{text:"",src:"product/image_G9_e (7).png"}, EN:{text:"",src:"product/image_G9_e (7).png"}  },
          {  CN:{text:"",src:"product/image_G9_e (8).png"}, EN:{text:"",src:"product/image_G9_e (8).png"}  },
          {  CN:{text:"",src:"product/image_G9_e (9).png"}, EN:{text:"",src:"product/image_G9_e (9).png"}  },

        ],
      },
      {
        id:3_4,
        name:{      
          CN:"智能健康儿童手环S6",
          EN:"Smart Healthy Children's Bracelet S6"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image_S6 (1).jpg"}, EN:{text:"",src:"product/image_S6_e (1).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (2).jpg"}, EN:{text:"",src:"product/image_S6_e (2).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (3).jpg"}, EN:{text:"",src:"product/image_S6_e (3).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (4).jpg"}, EN:{text:"",src:"product/image_S6_e (4).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (5).jpg"}, EN:{text:"",src:"product/image_S6_e (5).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (6).jpg"}, EN:{text:"",src:"product/image_S6_e (6).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (7).jpg"}, EN:{text:"",src:"product/image_S6_e (7).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (8).jpg"}, EN:{text:"",src:"product/image_S6_e (8).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (9).jpg"}, EN:{text:"",src:"product/image_S6_e (9).jpg"}  },
          {  CN:{text:"",src:"product/image_S6 (10).jpg"}, EN:{text:"",src:"product/image_S6_e (10).jpg"}  },
        ],
      }
    ]
  },
  {
    id:1,
    name:{
      CN:"智能理疗设备",
      EN:"Intelligent physiotherapy equipment"
    },
    least:false,
    text:{
      CN:"",
      EN:""
    },
    products:[],
    children:[
      {
        id:1_1,
        name:{
          CN:"刮痧仪",
          EN:"TCM (Traditional Chinese Medicine)scraping instrument"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_1 (1).png"}, EN:{text:"",src:"product/image1_1 (1).png"}  },
          {  CN:{text:"",src:"product/image1_1 (2).png"}, EN:{text:"",src:"product/image1_1 (2).png"}  },
          {  CN:{text:"",src:"product/image1_1 (3).png"}, EN:{text:"",src:"product/image1_1 (3).png"}  },
          {  CN:{text:"",src:"product/image1_1 (4).png"}, EN:{text:"",src:"product/image1_1 (4).png"}  },
          {  CN:{text:"",src:"product/image1_1 (5).png"}, EN:{text:"",src:"product/image1_1 (5).png"}  },
          {  CN:{text:"",src:"product/image1_1 (6).png"}, EN:{text:"",src:"product/image1_1 (6).png"}  },
          {  CN:{text:"",src:"product/image1_1 (7).png"}, EN:{text:"",src:"product/image1_1 (7).png"}  },

          // { text:{CN:"",EN:""},src:"product/image1_1 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_1 (7).png" },
        ],
      },
      {
        id:1_2,
        name:{
          CN:"经络笔",
          EN:"TCM Meridian Electronic pen"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_2 (1).png"}, EN:{text:"",src:"product/image1_2 (1).png"}  },
          {  CN:{text:"",src:"product/image1_2 (2).png"}, EN:{text:"",src:"product/image1_2 (2).png"}  },
          {  CN:{text:"",src:"product/image1_2 (3).png"}, EN:{text:"",src:"product/image1_2 (3).png"}  },
          {  CN:{text:"",src:"product/image1_2 (4).png"}, EN:{text:"",src:"product/image1_2 (4).png"}  },
          {  CN:{text:"",src:"product/image1_2 (5).png"}, EN:{text:"",src:"product/image1_2 (5).png"}  },
          {  CN:{text:"",src:"product/image1_2 (6).png"}, EN:{text:"",src:"product/image1_2 (6).png"}  },
          {  CN:{text:"",src:"product/image1_2 (7).png"}, EN:{text:"",src:"product/image1_2 (7).png"}  },
          {  CN:{text:"",src:"product/image1_2 (8).png"}, EN:{text:"",src:"product/image1_2 (8).png"}  },

          // { text:{CN:"",EN:""},src:"product/image1_2 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (7).png" },
          // { text:{CN:"",EN:""},src:"product/image1_2 (8).png" },
        ],
      },
      {
        id:1_3,
        name:{
          CN:"颈椎按摩仪",
          EN:"Cervical vertebra massage instrument"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_3 (1).png"}, EN:{text:"",src:"product/image1_3 (1).png"}  },
          {  CN:{text:"",src:"product/image1_3 (2).png"}, EN:{text:"",src:"product/image1_3 (2).png"}  },
          {  CN:{text:"",src:"product/image1_3 (3).png"}, EN:{text:"",src:"product/image1_3 (3).png"}  },
          {  CN:{text:"",src:"product/image1_3 (4).png"}, EN:{text:"",src:"product/image1_3 (4).png"}  },
          {  CN:{text:"",src:"product/image1_3 (5).png"}, EN:{text:"",src:"product/image1_3 (5).png"}  },
          {  CN:{text:"",src:"product/image1_3 (6).png"}, EN:{text:"",src:"product/image1_3 (6).png"}  },
          {  CN:{text:"",src:"product/image1_3 (7).png"}, EN:{text:"",src:"product/image1_3 (7).png"}  },
          {  CN:{text:"",src:"product/image1_3 (8).png"}, EN:{text:"",src:"product/image1_3 (8).png"}  },
          {  CN:{text:"",src:"product/image1_3 (9).png"}, EN:{text:"",src:"product/image1_3 (9).png"}  },
          {  CN:{text:"",src:"product/image1_3 (10).png"}, EN:{text:"",src:"product/image1_3 (10).png"}  },
          {  CN:{text:"",src:"product/image1_3 (11).png"}, EN:{text:"",src:"product/image1_3 (11).png"}  },
          {  CN:{text:"",src:"product/image1_3 (12).png"}, EN:{text:"",src:"product/image1_3 (12).png"}  },
          
          // { text:{CN:"",EN:""},src:"product/image1_3 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (7).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (8).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (9).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (10).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (11).png" },
          // { text:{CN:"",EN:""},src:"product/image1_3 (12).png" },
        ],
        video:[
          { text:{CN:"",EN:""},src:"product/video1_3 (1).mp4" },
        ]
      },
      {
        id:1_4,
        name:{
          CN:"护眼仪",
          EN:"Eye protector"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_4 (1).png"}, EN:{text:"",src:"product/image1_4 (1).png"}  },
          {  CN:{text:"",src:"product/image1_4 (2).png"}, EN:{text:"",src:"product/image1_4 (2).png"}  },
          {  CN:{text:"",src:"product/image1_4 (3).png"}, EN:{text:"",src:"product/image1_4 (3).png"}  },
          {  CN:{text:"",src:"product/image1_4 (4).png"}, EN:{text:"",src:"product/image1_4 (4).png"}  },
          {  CN:{text:"",src:"product/image1_4 (5).png"}, EN:{text:"",src:"product/image1_4 (5).png"}  },
          {  CN:{text:"",src:"product/image1_4 (6).png"}, EN:{text:"",src:"product/image1_4 (6).png"}  },
          {  CN:{text:"",src:"product/image1_4 (7).png"}, EN:{text:"",src:"product/image1_4 (7).png"}  },
          {  CN:{text:"",src:"product/image1_4 (8).png"}, EN:{text:"",src:"product/image1_4 (8).png"}  },
          
          // { text:{CN:"",EN:""},src:"product/image1_4 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (7).png" },
          // { text:{CN:"",EN:""},src:"product/image1_4 (8).png" },
        ],
      },
      {
        id:1_5,
        name:{
          CN:"Mini筋膜枪",
          EN:"Mini fascia gun"
        },
        least:true,
        text:{
          CN:"",
          EN:""
        },
        products:[
          {  CN:{text:"",src:"product/image1_5 (1).png"}, EN:{text:"",src:"product/image1_5 (1).png"}  },
          {  CN:{text:"",src:"product/image1_5 (2).png"}, EN:{text:"",src:"product/image1_5 (2).png"}  },
          {  CN:{text:"",src:"product/image1_5 (3).png"}, EN:{text:"",src:"product/image1_5 (3).png"}  },
          {  CN:{text:"",src:"product/image1_5 (4).png"}, EN:{text:"",src:"product/image1_5 (4).png"}  },
          {  CN:{text:"",src:"product/image1_5 (5).png"}, EN:{text:"",src:"product/image1_5 (5).png"}  },
          {  CN:{text:"",src:"product/image1_5 (6).png"}, EN:{text:"",src:"product/image1_5 (6).png"}  },
          {  CN:{text:"",src:"product/image1_5 (7).png"}, EN:{text:"",src:"product/image1_5 (7).png"}  },
          {  CN:{text:"",src:"product/image1_5 (8).png"}, EN:{text:"",src:"product/image1_5 (8).png"}  },
          {  CN:{text:"",src:"product/image1_5 (9).png"}, EN:{text:"",src:"product/image1_5 (9).png"}  },
          {  CN:{text:"",src:"product/image1_5 (10).png"}, EN:{text:"",src:"product/image1_5 (10).png"}  },
          {  CN:{text:"",src:"product/image1_5 (11).png"}, EN:{text:"",src:"product/image1_5 (11).png"}  },
          {  CN:{text:"",src:"product/image1_5 (12).png"}, EN:{text:"",src:"product/image1_5 (12).png"}  },
          {  CN:{text:"",src:"product/image1_5 (13).png"}, EN:{text:"",src:"product/image1_5 (13).png"}  },

          // { text:{CN:"",EN:""},src:"product/image1_5 (1).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (2).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (3).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (4).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (5).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (6).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (7).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (8).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (9).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (10).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (11).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (12).png" },
          // { text:{CN:"",EN:""},src:"product/image1_5 (13).png" },
        ],
        video:[
          { text:{CN:"",EN:""},src:"product/video1_5 (1).mp4" },
        ]
      }
    ]
  },
  {
    id:5,
    name:{      
      CN:"定制健康方案",
      EN:"Customized health programme"
    },
    least:true,
    text:{
      CN:"",
      EN:""
    },
    products:[
      {  CN:{text:"",src:"product/image3 (1).png"}, EN:{text:"",src:"product/image3 (1).png"}  },
      {  CN:{text:"",src:"product/image3 (2).png"}, EN:{text:"",src:"product/image3 (2).png"}  },
      {  CN:{text:"",src:"product/image3 (3).png"}, EN:{text:"",src:"product/image3 (3).png"}  },
      {  CN:{text:"",src:"product/image3 (4).png"}, EN:{text:"",src:"product/image3 (4).png"}  },
      {  CN:{text:"",src:"product/image3 (5).png"}, EN:{text:"",src:"product/image3 (5).png"}  },
      {  CN:{text:"",src:"product/image3 (6).png"}, EN:{text:"",src:"product/image3 (6).png"}  },
      {  CN:{text:"",src:"product/image3 (7).png"}, EN:{text:"",src:"product/image3 (7).png"}  },
      {  CN:{text:"",src:"product/image3 (8).png"}, EN:{text:"",src:"product/image3 (8).png"}  },
      {  CN:{text:"",src:"product/image3 (9).png"}, EN:{text:"",src:"product/image3 (9).png"}  },

    ],
  },
  
]

// export const Plan = {
//   "系统方案：我司为各中医院及中医馆提供公众号和小程序管理系统方案，主要功能有：中医在线问诊"
// 一、常规功能模块
// 1、固定模块的回访对话框
// 2、回访提醒功能
// 3、预约支付
// 4、问诊完后，除了处方，还有调理建议和辅助产品推荐的购买，不用患者再退出页面进到商城
// 5、通过网上问诊、视频问诊、电话问诊、医疗知识推送
// 二、语音课程和视频培训模块                                                                                                                   
// 1、视频培训，公众号营销、做微服务、微推广，能够向培训的学员展示的一个很好的渠道，中医培训要想向外界展示自己的师资力量、主要课程；
// 2、老师答疑。微信能够实现的另一个非常重要的功能就是老师答疑，微信公众号基于自身的文字和语音功能，能够实现学员与老师的沟通。
// 3、微投票。微投票是一项很重要的功能，教育培训利用微投票能够做的事情也是比较多的，例如能够帮助培训机构投票评选优秀学员，优秀老师等等。
// 4、报名预约系统。这个系统也是很重要的，学员无需线下去学校报名预约，直接通过微信就能完成这些事情。
// 5、绩查询系统、会员管理系统、文章助手、线下活动、支付中心、全景展示等等。
// 三、产品展示和商城                                                                                                                                 
// 1、合作商家联盟、广告位，优惠活动展示
// 可以实现在线浏览产品、寻医问药、在线诊疗等。可以让节省排队时间，在满足病人需求的时候，同时也能带来更多有治疗需求的病人，进行医患互动、复诊提醒和复诊预约等服务。 
// 2、通过中医馆公众号解决大众需求，小程序开展疾病管理，形成公众号和小程序互相补充服务的模式，彻底实习诊前、预检、预约、支付，诊中报告查询、检查支付、支付，诊后复诊提醒、医患互动的全流程服务模式。 
// 3、中医馆小程序和应该形成互补，以为提供面上医疗服务，以小程序为提供点上专病专科服务的蓝图基本形成，将小程序的功能定位在为科室的专科专病服务，将小程序设计成健康管理应用，更好实现科室与的互通和互动，医患互动、实现医医互动、患患互动功能。 
// 4、中医馆小程序还可以实现健康管理的需求，互联网医疗最终的目的是通过各行业互联互通，实现以个人为中心或者以家庭为中心的健康管理模式，让每个人都有一个健康管理平台，记录个人的、运动、、等各类信息，再经过大数据的分析，给到个人健康管理的意见和建议，让每个人都具有合适自己的健康管理模式。"

// }