// isMock
export const isMock = false;

// 请求连接前缀

export const imgUrl = 'https://www.anzyhealth.com/images/';



// export function lang(str) {

//     return str
// }


// export const language = window.language
// export const browserHistory = useRouterHistory(useBeforeUnload(createHistory))()